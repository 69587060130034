import * as React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Wave, { WaveType } from "../components/ui/wave"
import { PageType } from "./about.en"
import TopSection from "../components/consulting/TopSection"
import { BlogPostType } from "../components/blog/blogPostSection"
import Body from "../components/consulting/Body"
import CardsSection, { CardType } from "../components/common/cards/cardsSection"

const ConsultantPage = ({ path }) => {
  const { graphCmsPage, graphCmsBlogPost, allGraphCmsCard } = useStaticQuery(graphql`
    {
      graphCmsPage(id: { eq: "Page:ckz14w5xkrex30c58o1615ask:en:PUBLISHED" }) {
        heading
        body {
          html
        }
        seo {
          metaTitle
          metaDescription
          ogImage {
            url
          }
          focusKeywords
        }
      }
      graphCmsBlogPost(
        blogCategories: {
          elemMatch: { category: { eq: "howItWorks_app_consulting" } }
        }
        locale: { eq: en }
      ) {
        heading
        body {
          html
        }
      }
      allGraphCmsCard(
        filter: {
          cardCategory: { category: { eq: "jobseeker" } }
          locale: { eq: en }
        }
      ) {
        nodes {
          body
          heading
        }
      }

    }
  `)

  const data: PageType = graphCmsPage
  const post: BlogPostType = graphCmsBlogPost
  const cards: CardType[] = allGraphCmsCard.nodes

  return (
    <Layout initialLogoWhite initialTextColorThemeWhite path={path}>
      <SEO title="Consultant | cruto.io" meta={data?.seo} />
      <div>
        <TopSection {...data} />
      </div>

      <div className="container py-5">
        <CardsSection cards={cards} />
      </div>



      <Wave className={"u-c-orange"} type={WaveType.v4} />
      <div className="u-bg-orange">

        <Wave className={"u-c-white"} type={WaveType.v1} />
      </div>


      <Body heading={post.heading} body={post.body} />
      <Wave className={"u-c-orange"} type={WaveType.v5} />
    </Layout>
  )
}

export default ConsultantPage
